import React, { useContext, useState } from 'react'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import CCButton from '@components/buttons/cc-button'
import DynamicMenu from '@components/dynamic-menu'
import BaseLayout from '@components/layouts/base-layout'
import Pagination from '@components/pagination'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { useToastNotification } from '@helpers/notification-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { EyeIcon } from '@heroicons/react/24/outline'
import { Card } from '@material-tailwind/react'
import { NotificationsService } from '@services/api-notifications/notifications'
import { Notification } from '@services/api-notifications/notifications'

import NotificationInstructions from './instructions'
import SubscriptionsDialog from './settings-dialog'
const PAGE_COUNT = 50

export enum ReadCondtion {
  READ = 'read',
  UNREAD = 'unread',
}

const NotificationLogs = () => {
  const { company } = useContext(AuthContext)
  const { displayToastError } = useToastNotification()

  // eslint-disable-next-line
  const [isRead, setIsRead] = useState<Set<ReadCondtion>>( // TO ADD FOR FILTERS
    new Set([ReadCondtion.READ, ReadCondtion.UNREAD])
  )

  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(PAGE_COUNT)
  const [selectedNotifications, setSelectedNotifications] = useState<any>(
    new Set()
  )

  let isReadCondition: any = undefined
  if (isRead.size == 1) {
    if (isRead.values().next().value === ReadCondtion.READ) {
      isReadCondition = true
    } else {
      isReadCondition = false
    }
  }

  const { data, isLoading, refetch } = useQuery(
    [
      'Notification-getNotifications',
      company?.slug_name,
      page,
      perPage,
      isReadCondition,
    ],
    () => {
      return NotificationsService.getNotifications(
        undefined, // for Categories, default to all with undefined
        page + 1,
        perPage,
        isReadCondition // for isRead
      )
    },
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name,
    }
  )

  const { mutate: putNotificationReadStatus } = useMutation({
    mutationFn: ({ ids, isRead }: { ids: any[]; isRead: boolean }) => {
      return NotificationsService.setReadStatus(ids, isRead)
    },
    onSuccess: () => {
      refetch()
    },
    onError: (err: AxiosError) => {
      displayToastError(err.response?.status, 'Failed to save reads')
    },
  })

  const toggleEntity = (entity: any) => {
    setSelectedNotifications((prev: any) => {
      const updatedSet = new Set(prev)
      if (updatedSet.has(entity)) {
        updatedSet.delete(entity) // Remove if it was already selected
      } else {
        updatedSet.add(entity) // Add if not selected
      }
      return updatedSet
    })
  }

  const handleMarkSelected = () => {
    const selectedNotificationsArray = [...selectedNotifications]
    putNotificationReadStatus({ ids: selectedNotificationsArray, isRead: true })
    setSelectedNotifications(new Set())
  }

  const handleMarkAll = () => {
    if (data) {
      if (selectedNotifications.size === (data?.data.body?.length || 0)) {
        setSelectedNotifications(new Set())
      } else {
        setSelectedNotifications(new Set(data.data.body.map(e => e.id)))
      }
    }
  }

  const columns = [
    {
      title: (
        <label className="switch">
          <input
            type="checkbox"
            checked={
              !!data?.data.body.length &&
              selectedNotifications.size === (data?.data.body?.length || 0)
            }
            onChange={handleMarkAll}
          />
          <span className="slider"></span>
        </label>
      ),
      align: 'center',
      width: '50',
      render: (entity: Notification) => {
        const selection = entity.id
        const isSelected = selectedNotifications.has(selection)
        return (
          <label className="switch">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => toggleEntity(selection)}
            />
            <span className="slider"></span>
          </label>
        )
      },
    },
    {
      title: 'Occurred At',
      align: 'center',
      field: 'event.occurredAt',
      width: '150',
    },
    {
      title: 'Category',
      align: 'center',
      render: (e: Notification) => {
        return <div className="capitalize">{e.event.category}</div>
      },
      width: '150',
    },
    {
      title: 'Notification',
      align: 'left',
      render: (e: Notification) => {
        return (
          <div className="flex justify-between items-center gap-3">
            <div>{e.message}</div>
            <div
              className={`${
                !e.read ? 'bg-blue' : ''
              } rounded-full w-2 h-2 flex-shrink-0`}
            />
          </div>
        )
      },
    },
    {
      title: '',
      align: 'center',
      field: '',
      width: '50',
      render: (entity: Notification) => {
        const entityMenuItems = [
          {
            icon: <EyeIcon className="w-4" />,
            label: <div className="ml-2"> Mark as Read </div>,
            provider: () => {
              putNotificationReadStatus({ ids: [entity.id], isRead: true })
            },
          },
        ]

        return <DynamicMenu menuItems={entityMenuItems} />
      },
    },
  ]

  return (
    <BaseLayout title="xd">
      <NotificationInstructions />
      <Card className="p-6 rounded-md">
        <div className="flex items-center mb-6">
          <Typography
            variant="h6"
            className="font-semibold text-cc-text-primary"
          >
            Notifications
          </Typography>

          <div className="ml-auto flex items-center gap-3">
            {selectedNotifications.size > 0 && (
              <button
                disabled={selectedNotifications.size === 0}
                className={`border border-cc-primary-gray-medium h-9 px-3 pr-4 rounded-md flex items-center gap-1`}
                onClick={handleMarkSelected}
              >
                <Typography className="font-medium text-sm">
                  Mark Selected as Read
                </Typography>
              </button>
            )}

            <SubscriptionsDialog />

            <CCButton
              onClick={handleMarkAll}
              disabled={false}
              className="w-[120px]"
            >
              <Typography className="font-medium text-sm">
                {data?.data.body.length &&
                data?.data.body.length > 0 &&
                selectedNotifications.size === data?.data.body.length
                  ? 'Deselect All'
                  : 'Select All'}
              </Typography>
            </CCButton>
          </div>
        </div>
        <Table
          columns={columns as any}
          data={data?.data.body}
          loading={isLoading}
          rowClass={(rowData: Notification) => {
            const selected = rowData.id
            if (selectedNotifications.has(selected)) {
              return 'bg-cc-primary-hover'
            }
            return rowData.read ? 'bg-cc-tertiary-hover-deselected' : ''
          }}
        />
        <Pagination
          id="notification-table-pagination"
          total={data?.data?.page.total ?? 1}
          page={page}
          onChangePage={p => {
            setPage(p)
          }}
          limit={perPage}
          limits={Array(3)
            .fill('')
            .map((_, i) => PAGE_COUNT * (i + 1))}
          onChangeLimit={l => {
            setPage(0)
            setPerPage(l)
          }}
        />
      </Card>
    </BaseLayout>
  )
}
export default NotificationLogs
