import React, { useEffect, useState } from 'react'

import Next from '@components/buttons/next-button'
import Progress from '@components/stepper/orange-progress-bar'

const NotificationInstructions = () => {
  const [showInstruction, setShowInstruction] = useState(false)
  const [step, setStep] = useState<number>(0)

  useEffect(() => {
    const hasSeenTutorial = localStorage.getItem('hasSeenNotification')
    if (!hasSeenTutorial) {
      setShowInstruction(true)
      localStorage.setItem('hasSeenNotification', 'true') // Mark tutorial as seen
    }
  }, [])

  const instructions = [
    {
      mask: 'radial-gradient(circle at 34px calc(100% - 90px), transparent 40px, black 70px)',
      children: (
        <div className="absolute left-[16px] bottom-[130px] flex flex-col gap-3 items-end">
          <div className="text-cc-text-primary text-lg bg-white px-5 py-2 rounded-md">
            We&apos;ve added{' '}
            <span className="text-cc-primary-orange">Notifications</span> to
            Cascade — start tracking them here.
          </div>
          <Next onClick={() => setStep(prev => prev + 1)} />
        </div>
      ),
    },
    {
      mask: 'radial-gradient(circle at calc(100% - 270px) 140px, transparent 75px, black 100px)',
      children: (
        <div className="absolute right-[50px] top-[250px] flex flex-col gap-3 items-end">
          <div className="text-cc-text-primary text-lg bg-white px-5 py-2 rounded-md">
            <span className="text-cc-primary-orange">Subscriptions</span> allow
            you to change which types of notifications you will receive. <br />{' '}
            You have no subscriptions at this time - adjust{' '}
            <span className="text-cc-primary-orange">Subscriptions</span> to
            start receiving notifications.
          </div>
          <Next onClick={() => setStep(prev => prev + 1)} />
        </div>
      ),
    },
    {
      mask: 'radial-gradient(circle at calc(100% - 120px) 140px, transparent 75px, black 100px)',
      children: (
        <div className="absolute right-[50px] top-[250px] flex flex-col gap-3 items-end">
          <div className="text-cc-text-primary text-lg bg-white px-5 py-2 rounded-md">
            You can quickly{' '}
            <span className="text-cc-primary-orange">
              select all notifications.
            </span>
          </div>
          <Next onClick={() => setStep(prev => prev + 1)} />
        </div>
      ),
    },
    {
      mask: 'radial-gradient(circle at calc(100% - 137px) 252px, transparent 40px, black 90px)',
      children: (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-5 items-end">
          <div className="p-6 flex flex-col gap-5 text-cc-text-primary text-lg bg-white rounded-md w-[600px]">
            <div className="text-xl font-medium ">New Notifications</div>
            {/* TODO */}

            <div className="h-[300px] w-full overflow-hidden relative border border-black-30">
              <img
                className="absolute top-0 left-1/2 transform -translate-x-1/2 h-auto w-full"
                src="/notifications/notification-tutorial-NN.png"
                alt="Notification Tutorial"
              />
            </div>

            <div>
              <span className="text-cc-primary-orange">
                Unread notifications
              </span>{' '}
              are marked with a <span className="text-blue">blue dot,</span>{' '}
              while read notifications are grayed out.
            </div>
          </div>

          <Next onClick={() => setStep(prev => prev + 1)} />
        </div>
      ),
    },
    {
      children: (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-5 items-end">
          <div className="p-6 flex flex-col gap-5 text-cc-text-primary text-lg bg-white rounded-md w-[600px]">
            <div className="text-xl font-medium">
              Multiselecting Notifications
            </div>
            {/* TODO */}
            <div className="h-[300px] bg-gray-50">
              <img src="/notifications/notification-tutorial.gif" />
            </div>
            <div>
              Select individual notifications and manage in bulk with actions
              such as{' '}
              <span className="text-cc-primary-orange">
                Mark Selected as Read
              </span>
              .
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-white text-lg">Get Started Now</div>
            <Next onClick={() => setShowInstruction(false)} />
          </div>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (step == instructions.length) {
      setShowInstruction(false)
    }
  })

  return showInstruction ? (
    <div>
      <Instruction mask={instructions[step]?.mask ?? undefined}>
        {instructions[step].children}
      </Instruction>
      <Progress
        step={step}
        totalSteps={instructions.length}
        navigationFn={setStep}
        closeFn={() => setShowInstruction(false)}
      />
    </div>
  ) : (
    <div></div>
  )
}

const Instruction = ({
  children,
  mask,
}: {
  children: JSX.Element
  mask?: string
}) => {
  return (
    <div
      className="fixed inset-0 bg-gray-800/50 transition-all backdrop-blur-sm duration-300 z-[999] pointer-events-none"
      style={{
        WebkitMaskImage: mask,
        maskImage: mask,
      }}
    >
      <div className="pointer-events-auto">{children}</div>
    </div>
  )
}

export default NotificationInstructions
