import notificationsApiClient from './client'

enum Channel {
  EMAIL = 'email',
  PUSH = 'push',
  SMS = 'sms',
}

enum NotificationStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  FAILED = 'failed',
  SENT = 'sent',
}

interface EventDTO {
  id: number
  name: any // Enum
  category: any // Enum
  slugName: string
  occurredAt: string
  parameters?: any // Array of parameters
}
export interface Notification {
  createdAt?: string
  updatedAt?: string
  id?: number
  user: string
  event: EventDTO
  channel: Channel
  status: NotificationStatus
  read?: boolean
  failureCount?: number
  errorMessage?: string
  sentAt?: string
  message?: string
}

interface NotificationQuery {
  body: Notification[]
  page: {
    size: number
    offset: number
    total: number
  }
}

export class NotificationsService {
  static async getNotifications(
    categories: string[] | undefined,
    page: number,
    size: number,
    isRead: boolean | undefined
  ) {
    const params: any = {
      categories: categories?.join(),
      page,
      size,
    }

    if (isRead !== undefined) {
      params.is_read = isRead
    }

    return notificationsApiClient.get<NotificationQuery>('/list', {
      params: params,
    })
  }

  static async setReadStatus(notificationIds: any[], isRead: boolean) {
    await notificationsApiClient.put<void>('/set-read-status', {
      notification_ids: notificationIds,
      is_read: isRead,
    })
  }

  static async fetchCategories(): Promise<
    {
      category: string
      name: string
      description: string
    }[]
  > {
    const { data } = await notificationsApiClient.get<any>('/categories')
    return data
  }

  static async fetchUserSubscriptions() {
    const { data } = await notificationsApiClient.get<any>(
      '/user-subscriptions'
    )
    return data
  }

  static async updateUserSubscriptions(subscriptions: any[]) {
    const { data } = await notificationsApiClient.post<any>(
      '/update-subscriptions',
      subscriptions
    )
    return data
  }
}
