import React, { useContext, useEffect, useRef, useState } from 'react'
import { AxiosError } from 'axios'
import { isEqual } from 'lodash'
import { useMutation, useQuery } from 'react-query'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import {
  Dialog,
  DialogBody,
  DialogFooter,
} from '@components/basic-dialog/custom-dialog'
import { setUpdateHelper, Toggler } from '@components/buttons/checkbox-button'
import AuthContext from '@contexts/auth'
import { useToastNotification } from '@helpers/notification-hook'
import { Cog8ToothIcon } from '@heroicons/react/24/outline'
import { NotificationsService } from '@services/api-notifications/notifications'

const SubscriptionsDialog = () => {
  const { company } = useContext(AuthContext)

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const { displayToastSuccess, displayToastError } = useToastNotification()

  const [subscriptions, setSubscriptions] = useState<Set<any>>(new Set())
  const [draftSubscriptions, setDraftSubscriptions] = useState<Set<string>>(
    new Set()
  )

  const { data: currSubscriptions, refetch: refetchInitialSubscriptions } =
    useQuery(['Notification-getSubscriptions', company?.slug_name], () =>
      NotificationsService.fetchUserSubscriptions()
    )

  useEffect(() => {
    if (currSubscriptions?.length) {
      const init = new Set(currSubscriptions[0]['eventCategories'])
      if (!isEqual(subscriptions, init)) {
        setSubscriptions(init) // Update `subscriptions`
      }
    }
  }, [currSubscriptions])

  const { data, isLoading: isLoadingCategories } = useQuery(
    ['Notifications', 'Categories'],
    () => NotificationsService.fetchCategories()
  )

  const toggleEntity = (entity: string) => {
    setDraftSubscriptions(prev => setUpdateHelper(prev, entity))
  }

  const labels = data?.map((option: any) => {
    return (
      <Toggler
        key={option.category}
        option={option.category}
        title={option.name}
        description={option.description}
        toggleFunction={toggleEntity}
        checkedSet={draftSubscriptions}
      />
    )
  })

  const subscriptionsRef = useRef(subscriptions)
  useEffect(() => {
    if (!isEqual(subscriptions, subscriptionsRef.current)) {
      setDraftSubscriptions(subscriptions)
      subscriptionsRef.current = subscriptions
    }
  }, [subscriptions])

  const { mutate: updateSubscriptions } = useMutation({
    mutationFn: () => {
      // currently we are only dealing with email. when we have multiple channels we need to update our subscriptions and resulting array to be more complex
      const emailSubscription = {
        channel: 'email',
        eventCategories: Array.from(draftSubscriptions),
      }
      return NotificationsService.updateUserSubscriptions([emailSubscription])
    },
    onSuccess: (data: any) => {
      displayToastSuccess('Succesfully saved subscriptions.')
      // transform incoming data to set
      const newSubscriptions = data.reduce(
        (accu: any, curr: any) => {
          // Append the category to the appropriate channel array
          accu[curr.channel].push(curr.category)
          return accu
        },
        { email: [], sms: [], push: [] }
      )

      const newEmailSet: Set<string> = new Set(newSubscriptions.email)
      setSubscriptions(newEmailSet)
      setDraftSubscriptions(newEmailSet)
      refetchInitialSubscriptions()
    },
    onError: (err: AxiosError) => {
      displayToastError(err.response?.status, 'Failed to save subscriptions.')
    },
  })

  const handleSave = async () => {
    updateSubscriptions()
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDraftSubscriptions(subscriptions) // reset subscriptions
  }

  return (
    <div className="h-full">
      <button
        className="border border-cc-primary-gray-medium h-9 px-3 pr-4 rounded-md flex items-center gap-1"
        onClick={() => setOpenDialog(true)}
      >
        <Cog8ToothIcon className="text-cc-icon-primary h-6" strokeWidth={1.2} />
        <Typography className="font-medium text-sm">Subscriptions</Typography>
      </button>
      <Dialog
        title="Subscription Settings"
        isOpen={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogBody>
          {!isLoadingCategories && !!subscriptions ? (
            <div className="flex grid gap-y-2 grid-cols-1">{labels}</div>
          ) : (
            <div>loading</div>
          )}
        </DialogBody>
        <DialogFooter>
          <div className="flex justify-end">
            <Button
              onClick={handleSave}
              disabled={isEqual(subscriptions, draftSubscriptions)}
              className={`relative overflow-hidden border-[1.5px] py-2 px-6 border-primary-main text-primary-main 
            hover:bg-primary-hover hover:text-neutral-white hover:border-primary-hover
            disabled:text-cc-locked/50 disabled:bg-neutral-white disabled:cursor-not-allowed
            disabled:border-[1.5px]
            ${
              isEqual(subscriptions, draftSubscriptions) ? '' : 'animate-pulse'
            }`}
            >
              Save
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  )
}

export default SubscriptionsDialog
