export const getOriginLabel = (doc: any) => {
  if (
    (doc?.dropbox_api_supp === 's3 upload' &&
      // we look at form values to check if the document was generated but didn't require any signers
      (!doc?.form_values || !!doc?.form_values.file)) ||
    (!doc?.dropbox_api_supp && doc?.category === 'Advance Request')
  ) {
    return 'User Uploaded'
  }
  if (doc?.dropbox_api_supp === null && doc?.category !== 'Advance Request') {
    return 'Pending'
  }
  return 'Cascade Generated'
}
