/* We created this blob converter because certain browsers like Safari does not have intrinsic ability to deserialize large strings (e.g deserializing a s3 data token). In cases like so, we will transform the long char to binary storage for a shorter deserialization mechanism from a temporary URL. 

It is recommended that we DON'T use this and use presigned URLs to avoid deserialization all together.*/

export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}
