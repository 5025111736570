import React, { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { AppState, Auth0Provider } from '@auth0/auth0-react'
import AppRoutes from '@components/app-routes'
import { AUTH_AUDIENCE, AUTH_CLIENT_ID, AUTH_DOMAIN } from '@constants/config'
import ApplicationCustomContext, {
  ApplicationCustomContextValues,
} from '@contexts/application-custom-context'
import AuthContext from '@contexts/auth'
import LanguagesProvider from '@contexts/language/provider'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import Company from '@interfaces/company'
import { LoadingData } from '@interfaces/loading'
import { UserMetadata } from '@interfaces/user-metadata'
import { ThemeProvider } from '@material-tailwind/react'

import theme from './theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  const navigate = useNavigate()
  const [userMetadata, setUserMetadata] = useState<UserMetadata>()
  const [companyDetails, setCompanyDetails] = useState<Company>()
  const [optionFilters, setOptionFilters] = useState<LayoutFilter>({})
  const [activeFilters, setActiveFilters] = useState<LayoutFilter>({})
  const [appliedFilters, setAppliedFilters] = useState<LayoutFilter>({})
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [showSidemenu, setShowSidemenu] = useState<boolean>(true)
  const [loadingData, setLoadingData] = useState<LoadingData>({})
  const [showTable, setShowTable] = useState<boolean>(false)
  const [showRawFinancials, setShowRawFinancials] = useState<boolean>(true)
  const context: ApplicationCustomContextValues = {
    setShowTable: setShowTable,
    showTable: showTable,
    setShowRawFinancials: setShowRawFinancials,
    showRawFinancials: showRawFinancials,
  }

  const onRedirectCallback = (appState?: AppState | undefined) => {
    navigate(appState?.returnTo ? appState.returnTo : window.location.href, {
      replace: true,
    })
  }

  return (
    <Auth0Provider
      domain={AUTH_DOMAIN || ''}
      clientId={AUTH_CLIENT_ID || ''}
      redirectUri={`${window.location.origin}/auth-callback`}
      audience={AUTH_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
    >
      <QueryClientProvider client={queryClient}>
        <LanguagesProvider>
          <ThemeProvider value={theme}>
            <AuthContext.Provider
              value={{
                userMetadata: userMetadata,
                setUserMetadata,
                company: companyDetails,
                setCompanyDetails,
                optionFilters,
                setOptionFilters,
                activeFilters,
                setActiveFilters,
                appliedFilters,
                setAppliedFilters,
                showFilter,
                setShowFilter,
                showSidemenu,
                setShowSidemenu,
                loadingData,
                setLoadingData,
              }}
            >
              <ApplicationCustomContext.Provider value={context}>
                <AppRoutes />
              </ApplicationCustomContext.Provider>
            </AuthContext.Provider>
          </ThemeProvider>
        </LanguagesProvider>
      </QueryClientProvider>
    </Auth0Provider>
  )
}

export default App
